import { render, staticRenderFns } from "./DataRole.vue?vue&type=template&id=4a653955&scoped=true&"
import script from "./DataRole.vue?vue&type=script&lang=js&"
export * from "./DataRole.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a653955",
  null
  
)

export default component.exports