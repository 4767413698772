<template>
  <div class="Room bg-white">
    <Row :gutter="8" class="px-1">
      <i-col span="24" class="align-justify">
        <Spin size="large" fix v-if="spinShow">
          <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
          <div>正在加载...</div>
        </Spin>
        <RadioGroup v-model="DataBorder" @on-change="checkDataRole">
          <Radio
            border
            v-for="item in roleList"
            :key="item.value"
            :label="item.value"
          >{{item.label}}</Radio>
        </RadioGroup>
        <Form :model="dataRoleList[DataRoleId]">
          <FormItem label></FormItem>
          <FormItem label="测评记录：">
            <i-switch v-model="switch1" @on-change="change1"></i-switch>
            <span style="font-size:12px;color:#999;margin-left:5px">* {{regionText1}}</span>
          </FormItem>
          <FormItem label="测评任务：">
            <i-switch v-model="switch2" @on-change="change2"></i-switch>
            <span style="font-size:12px;color:#999;margin-left:5px">* {{regionText2}}</span>
          </FormItem>
        </Form>
        <Button type="primary" :disabled="DataRoleId == 1" v-if="DataRoleId == 1">超级管理员身份权限配置不允许修改</Button>
        <Button type="primary" v-else @click="setRole">修改</Button>
      </i-col>
    </Row>
  </div>
</template>
<script>
export default {
  name: "Role",
  data() {
    return {
      switch1: false,
      switch2: false,
      regionText1: "",
      regionText2: "",
      switch1: false,
      formItem: {
        testData: "male",
        scaleTask: "male",
      },
      spinShow: true,
      DataBorder: 1,
      roleName: "",
      DataRoleId: "1",
      roleList: [],
      dataRoleList: [],
    };
  },
  mounted() {
    this.$get("Role/GetRoleList", {
      requestName: "GetRoleList",
    }).then((res) => {
      this.roleList = res.data.responseList;
      this.spinShow = false;
      this.getDataRole();
    });
  },
  methods: {
    change1(state) {
      this.regionText1 =
        state == true ? "可查看全部数据" : "仅可查看所属本人数据";
    },
    change2(state) {
      this.regionText2 =
        state == true ? "可查看全部数据" : "仅可查看所属本人数据";
    },
    checkDataRole(row) {
      this.DataRoleId = row;
      this.getDataRole();
    },
    getDataRole() {
      this.spinShow = true;
      this.$get("Role/GetDataRole", {
        roleId: this.DataRoleId,
      }).then((res) => {
        this.dataRoleList = res.data.responseList;
        this.switch1 = this.dataRoleList[0].selectRegion == 1 ? true : false;
        this.switch2 = this.dataRoleList[1].selectRegion == 1 ? true : false;
        this.regionText1 =
          this.switch1 == true ? "可查看全部数据" : "仅可查看所属本人数据";
        this.regionText2 =
          this.switch2 == true ? "可查看全部数据" : "仅可查看所属本人数据";
        this.spinShow = false;
      });
    },
    setRole() {
      this.$Modal.confirm({
        title: "提示",
        content: "<p>确认修改权限配置?</p>",
        loading: true,
        onOk: () => {
          this.$post("Role/SetDataByRole", {
            roleId: this.DataRoleId,
            switch1: this.switch1,
            switch2: this.switch2,
          }).then((res) => {
            if (res.data.code) {
              this.$Modal.remove();
              this.$Message.success(res.data.msg);
            } else {
              this.$Modal.remove();
              this.$Message.error(res.data.msg);
            }
          });
        },
        onCancel: () => {
          this.$Message.error("取消修改!");
        },
      });
    },
  },
};
</script>
<style lang="less" scoped="scoped">
</style>
